import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader, Container, Col, Row } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import StrategicActivityClassificationEditor from 'components/StrategicActivityClassificationEditor';
import ExportToExcel from 'lib/ExportToExcel';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';

// TODO: Add input validation graphics
// TODO: add sorting to table header

const numberComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  // Parsing the percentage values
  let numA = parseFloat(valueA);
  let numB = parseFloat(valueB);

  // Handling NaN cases
  if (isNaN(numA)) {
    numA = 0;
  }
  if (isNaN(numB)) {
    numB = 0;
  }

  // Comparing the parsed values
  return numA - numB;
};

const ActivitiesTab = (props) => {
  const { activities, setActivities, replaceActivityAction, name, website, businessDetails, businessDescription } = props;

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [agGridContext, setAgGridContext] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isGeneratingActivities, setIsGeneratingActivities] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);
  
  const prevRowDataRef = useRef();

  useEffect(() => {
    setAgGridContext({ isLoading: isLoading,  prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = activities;
  }, [activities, isLoading]); 

  // Add activity ID if it doesn't exist for each row.
  // TODO: Fix. This is here to deal with backwards compatibility, but likely will cause issues if activities are created from other places without an ID. Users would need to go to the activities tab to generate IDs. <-- bad
  // Move to strategy index page?
  useEffect(() => {
    let updated = false;
    const updatedActivities = activities.map(activity => {
      if (!activity.id) {
        updated = true;
        return { ...activity, id: generateuniqueIshId() };
      }
      return activity;
    });

    // Only update state if changes were made
    if (updated) {
      setActivities({ type: 'overwrite', payload: updatedActivities });
    }
  }, [activities, setActivities]);

  const rowData = useMemo(() => {
    if (Array.isArray(activities)) {
      return activities.map(activity => ({
        id: activity.id,
        function: activity.function,
        activity: activity.activity,
        classification: activity.classification,
        description: activity.description,
        emoji: activity.emoji,
      }));
    }
    return [];
  }, [activities]);

  const baseColumnConfig = {
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  }

  const kpiColumnConfig = {
    editable: false,
    sortable: true,
    resizable: true,
    flex: 1,
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: ['lessThan', 'greaterThan', 'equals', 'notEqual'],
    },
    comparator: numberComparator
  }

  const columns = [
    { headerName: 'ID', field: 'id', hide: true, ...baseColumnConfig},
    { headerName: '', field: 'emoji', ...baseColumnConfig, width: 30, flex: 0 },
    { headerName: 'Function', field: 'function', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    { headerName: 'Activity', field: 'activity', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    { headerName: 'Classification', field: 'classification', cellRendererFramework: LoadingCellRenderer, cellEditorFramework: 'classificationEditor', editable: true, ...baseColumnConfig },
    { headerName: 'Description', field: 'description', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig, flex: 5 },
    { headerName: '', field: 'delete_row', cellRenderer: params => <DeleteRowButtonRenderer {...params} activities={activities} setActivities={setActivities} />, cellClass: 'delete-row-button-cell', width: 50, flex: 0 }
  ];
  

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    
    params.api.sizeColumnsToFit();
  }, []);

  const onCellValueChanged = useCallback(params => {
    setActivities({ type: 'update', payload: params.data });
  }, [setActivities]);

  const onCellEditingStarted = useCallback(() => {
    setIsEditing(true);
  }, []);
  
  const onCellEditingStopped = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onCellFocused = useCallback((params) => {
    if (!isEditing) {
      gridApi.stopEditing();
    }
  }, [isEditing, gridApi]);

  const addNewRow = () => {
    const newActivityRow = {
      id: generateuniqueIshId(),
      activity: `Activity ${Array.isArray(activities) ? activities.length + 1 : 1}`,
      function: '',
      description: ''
    };
    setActivities({type: 'prepend', payload: [newActivityRow] });
    return newActivityRow;
  };

  const DeleteRowButtonRenderer = ({ api, node, data }) => {
    const handleDelete = () => {
      const updatedActivities = activities.filter(activity => activity.id !== data.id);
      setActivities({ type: 'overwrite', payload: updatedActivities });
  
      setTimeout(() => {
        api.setRowData(updatedActivities);
      }, 0);
    };
  
    return (
      <span onClick={handleDelete}>
        <SVG
          icon="delete.svg#delete"
          iconWidth={24}
          iconHeight={24}
          iconClassName="button__icon button__icon-red"
        />
      </span>
    );
  };

  const onCellContextMenu = (event) => {
    setContextMenu({
      x: event.event.pageX,
      y: event.event.pageY,
      rowData: event.data,
    });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const onContextMenu = (e) => {
    e.preventDefault();
  };  

  const getRevenueFormated = (revenue) => {
    if (revenue) {
      if (revenue.currency) {
      return `${revenue.amount} ${revenue.currency.currencyCode}`;
      }
      return `${revenue.amount}`;
    }
    return '';
  };

  const generateSyntheticActivities = async () => {
    if (isGeneratingActivities) return;
    setIsGeneratingActivities(true);
    const blankActivity = addNewRow();
    setIsLoading({ type: 'add_to_list', payload: blankActivity.id });

    const apiPayload = {
        'prompt_template': 'generate_activity_list',
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'num_employees': businessDetails.num_employees ? businessDetails.num_employees : '',
        'revenue': businessDetails.revenue ? getRevenueFormated(businessDetails.revenue) : '',
        'business_description': businessDescription ? businessDescription : '',
        'activities': activities ? activities : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedactivities = apiResponse.message[0]['activities'];

      try {
        setActivities({ type: 'prepend', payload: generatedactivities } );
      } catch (error) {
          console.error('Error parsing API response:', error);
      }
    } catch (error) {
        console.error('Error sending chat message:', error);
    }
    setIsGeneratingActivities(false);
    setIsLoading({ type: 'remove_from_list', payload: blankActivity.id });
    setActivities({ type: 'remove', payload: blankActivity });
  };

  const splitActvitity = async (activity) => {
    console.log('Splitting activity:', activity);
    setIsLoading({ type: 'add_to_list', payload: [activity.id] });
    const apiPayload = {
      'prompt_template': 'split_activity',
      'activity_to_split': activity.activity,
      'description': activity.description,
      'business_name': name ? name : '', 
      'business_website': website ? website : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'num_employees': businessDetails.num_employees ? businessDetails.num_employees : '',
      'revenue': businessDetails.revenue ? getRevenueFormated(businessDetails.revenue) : '',
      'business_description': businessDescription ? businessDescription : '',
      'activities': activities ? activities : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const newActivities = apiResponse.message[0]['activities'];
      return newActivities;
    } catch (error) {
      console.error('Error sending chat message:', error);
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: [activity.id] });
    }
  }

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newActivities = [];
    data.rows.forEach((row) => {
      let a = {
        id: generateuniqueIshId(),
        function_id: generateuniqueIshId(),
        activity: row[data.activity],
        classification: row[data.classification],
        description: row[data.description],
        function: (data.function !== undefined && row[data.function]) || 'Default',
      }
      if (newActivities.find(c => c.activity === a.activity && c.function === a.function)) {
        return;
      }
      newActivities.push(a);
    })

    setActivities({ type: data.write_mode, payload: newActivities });
  }, [setActivities]);

  return (
    <div className="function-tab">
      <div className="function-tab-header">
      </div>
      
      <Row xs="8" className="align-items-left">
        <Col>
          <button onClick={() => generateSyntheticActivities()} disabled={isGeneratingActivities} className="button ms-auto">
            { activities.length !== 0 ? 'Generate more Activities' : 'Generate Activities' }
            <span className="material-symbols-outlined">
              neurology
            </span>
          </button>
          <button onClick={() => setShowImport(true)} className="button ms-auto">
            Import Activities
            <span className="material-symbols-outlined">
              upload
            </span>
          </button>
          <ExportToExcel 
            buttonLabel='Export Activities' 
            data={activities} 
            fileName={name + ' Activity list from Reconfig'} 
            sheetName={name + ' Activity list'} 
            fieldsToExport={['id', 'function', 'activity', 'description']}
          />
          <Button
            color="primary"
            onClick={addNewRow}
            text="Add Activity"
            className="button ms-auto"
            icon="plus.svg#plus"
          />
          <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
            <ModalHeader>Import Activities</ModalHeader>
            <CSVWizard
              toggle={() => setShowImport(false)}
              handleSubmit={handleImportSubmit}
              specifyColumns={[
                { key: 'function', label: 'Function Column', placeholder: 'Function', },
                { key: 'activity', label: 'Activity Column', placeholder: 'Activity', required: true },
                { key: 'classification', label: 'Classitification Column', placeholder: 'Strategic/Support', },
                { key: 'description', label: 'description', placeholder: 'Activity description' },
              ]}
            />
          </Modal>
        </Col>
      </Row>
        <div className="mt-2 ag-theme-alpine ag-row grid-height-400" onContextMenu={onContextMenu} > 
          <AgGridReact
            columnDefs={columns}
            rowData={rowData}
            onGridReady={onGridReady}
            context={agGridContext}
            onCellValueChanged={onCellValueChanged}
            onCellEditingStarted={onCellEditingStarted}
            onCellEditingStopped={onCellEditingStopped}
            onCellContextMenu={onCellContextMenu}
            onCellFocused={onCellFocused}
            components={{ classificationEditor: StrategicActivityClassificationEditor }}
          />
        </div>
        {contextMenu && (
            <AggridContextMenu
              buttonLabel="Split Activity"
              x={contextMenu.x}
              y={contextMenu.y}
              onClose={closeContextMenu}
              onGenerate={() => {
                splitActvitity(contextMenu.rowData).then(updatedActivities => {
                  setActivities({type: 'replace', payload: {old: contextMenu.rowData, new: updatedActivities}});
                  closeContextMenu();
                }).catch(error => {
                  console.error('Error splitting activity: ', error);
                });
              }}
              />
        )}
    </div>
  );
}

export default ActivitiesTab;
