/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import { ReactSortable } from 'react-sortablejs';
import classnames from 'classnames';

import Button from 'components/Button';
import EditableText from 'components/EditableText';
import { generateuniqueIshId, getColorByName, getTermFilteredNode, toSlug } from 'lib/helper';
import _ from 'lib/lodashFunctions';
import { installClickOutside } from 'lib/hooks';
import Item from './Item';
import ItemEditModal from './ItemEditModal';
import SVG from 'components/SVG';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import Loading from 'components/Loading';
import { postSearchApi } from 'api/chat';

function notEqualItemList(oldData, newData) {
  if (oldData.length !== newData.length) return true;
  for (let j = 0; j < oldData.length; j++) {
    if (oldData[j].id !== newData[j].id) return true;
  }
  return false;
}

function notEqual(oldData, newData) {
  if (oldData.length !== newData.length) return true;
  for (let i = 0; i < oldData.length; i++) {
    const og = oldData[i];
    const ng = newData[i];
    if (og.group_name !== ng.group_name) return true;
    if (notEqualItemList(og.items, ng.items)) return true;
  }
  return false;
}

function sortData(data) {
  return data
    .map((group) => ({
      ...group,
      items: [...group.items].sort((a, b) => {
        return a.id - b.id;
      }),
    }))
    .sort((a, b) => {
      if (a.items.length === 0) return true;
      if (b.items.length === 0) return false;
      return a.items[0].id - b.items[0].id;
    });
}

function shouldRun(oldData, newData) {
  if (oldData.length !== newData.length) return true;

  const sortedOldData = sortData(oldData);
  const sortedNewData = sortData(newData);
  for (let i = 0; i < sortedOldData.length; i++) {
    const og = sortedOldData[i];
    const ng = sortedNewData[i];
    if (og.items.length !== ng.items.length) return true;
    for (let j = 0; j < og.items.length; j++) {
      if (og.items[j].id !== ng.items[j].id) return true;
    }
  }
  return false;
}

function addDuplicate(a, item) {
  const idx = a.findIndex(i => i.id === item.id);
  const newItem = _.cloneDeep(item);
  newItem.id = `${Math.floor(1000000000 + Math.random() * 9000000000)}`;
  if (idx > -1) {
    return [...a.slice(0, idx), newItem, ...a.slice(idx)];
  }

  return a;
}

function SortableItemsWrapper({
  items,
  setList,
  setChoosen,
  setDialogItem,
  choosen,
  linkmap,
  labels,
  width,
  height,
  demo,
  onCopy,
  onDelete,
}) {
  if (!items) return null;

  let dragClass = 'drag-item';
  if (demo) {
    dragClass = dragClass + '-demo';
  }

  return (
    <ReactSortable
      list={items}
      setList={(list) => setList(list, items)}
      animation={250}
      group="all-group"
      ghostClass="ghost-item"
      dragClass={dragClass}
      fallbackClass="fallback-item"
      forceFallback={true}
      fallbackOnBody={true}
    >
      {items.map((item) => (
        <Item
          key={`item-${item.id}`}
          item={item}
          onClick={() => setChoosen(item)}
          onDoubleClick={() => setDialogItem(item)}
          onCopy={() => onCopy(item)}
          onDelete={() => onDelete(item)}
          choosen={choosen}
          linkmap={linkmap}
          labels={labels}
          width={width}
          height={height}
        />
      ))}
    </ReactSortable>
  );
}

function GroupWrapper({ renderGroup, groupKey, group }) {
  return <div className="group">{renderGroup(groupKey, group)}</div>;
}

function SortableGroupsWrapper({
  groups,
  setList,
  renderGroup,
  className,
  deselect,
  demo,
}) {
  if (!groups) return null;

  let dragClass = 'drag-group';
  if (demo) {
    dragClass = dragClass + '-demo';
  }

  return (
    <ReactSortable
      list={groups}
      setList={setList}
      animation={250}
      ghostClass="ghost-group"
      dragClass={dragClass}
      fallbackClass="fallback-group"
      forceFallback={true}
      fallbackOnBody={true}
      className={className}
    >
      {groups.map((group, i) => {
        return (
          <GroupWrapper
            //key={`group-${i}`}
            key={group.groupId}
            deselect={deselect}
            renderGroup={renderGroup}
            group={group}
            groupKey={i}
          />
        );
      })}
    </ReactSortable>
  );
}

function GroupHeader({ group, onRemove, onChangeTitle, onClick, fte }) {
  return (
    <div className="group-header">
      <div className="p fte-sum-header">
        FTE {fte}
      </div>
      <EditableText
        value={group.group_name}
        setValue={onChangeTitle}
        onClick={onClick}
        selected={group.select}
      />
       <div
        className={classnames('close', { hidden: !group.select })}
        onMouseDown={onRemove}
      >
        ✖
      </div>
    </div>
  );
}

class OrganizationTab extends Component {
  constructor(props) {
    super(props);
    this.maxLabelWidth = {};
    this.calcMaxLabelWidth(props.data.nodes);
    this.state = {
      ...this.convertData(props.data),
      height: 88,
      width: this.maxLabelWidth['name'],
      generatingTimeUtilizationLoading: false,
    };
    this.state.data.defaultLabels = props.data.defaultLabels;
    this.lastData = this.state.data;
    this.linkmap = this.getLinkMap(props.data);
  }

  calcMaxLabelWidth = (nodes) => {
    nodes.forEach((node) => {
      _.forEach(node, (value, key) => {
        if (typeof value === 'string') {
          const width = Math.max(
            ...value.split(' ').map((s) => this.calcWidth(s)),
          );
          if (!this.maxLabelWidth[key]) {
            this.maxLabelWidth[key] = Math.max(width, 100);
          } else {
            this.maxLabelWidth[key] = Math.max(width, this.maxLabelWidth[key]);
          }
        }
      });
    });
  };

  convertData = (rawData) => {
    const { groupOptions } = rawData;
    let maxGroupIndex = -1;
    if (groupOptions?.length > 0) {
      maxGroupIndex = Math.max(...groupOptions.map(
        item => item?.value.split('gr')[1],
      ).filter(item => !!item));
    }
    this.colorOptions = {};

    if (rawData.nodes) {
      const nodes = rawData.nodes.map((node) => ({
        ...node,
        //color: node.employee_type === 'GenAI Agent' ? 'agent-card' : 'employee-card',
        color: getColorByName(node.group),
      }));
      const assigned = groupOptions.filter(v => v.value !== 'gr').map(({ value, label }) => ({
        items: nodes.filter(n => n.group === value),
        group_name: label,
        groupId: value,
      }));
      const unassigned = nodes.filter(n => n.group === 'gr');

      return {
        data: {
          assigned,
          unassigned,
        },
        maxGroupIndex,
      };
    }
    return {
      data: [],
      maxGroupIndex,
    };
  };

  getLinkMap = (data) => {
    let ret = {};
    data.links.forEach((link) => {
      if (link.source && link.target) {
        ret[`${link.source.id}-${link.target.id}`] = link;
        ret[`${link.source.id}`] = [...(ret[`${link.source.id}`] || []), link];
        ret[`${link.target.id}`] = [...(ret[`${link.target.id}`] || []), link];
      }
    });
    return ret;
  };

  addGroup = () => {
    const { assigned } = this.state.data;
    const { maxGroupIndex } = this.state;

    let groupCount = assigned.length;
    while (
      assigned.find(
        (group) => group.group_name === `Group ${groupCount + 1}`,
      )
      ) {
      groupCount += 1;
    }

    this.setState(
      {
        data: {
          ...this.state.data,
          assigned: [
            ...this.state.data.assigned,
            {
              group_name: `Group ${groupCount + 1}`,
              groupId: `gr${maxGroupIndex + 1}`,
              items: [],
            },
          ],
        },
        maxGroupIndex: maxGroupIndex + 1,
      },
      () => this.save(),
    );
  };

  save = (force = false) => {
    if (
      force ||
      notEqual(this.state.data.assigned, this.lastData.assigned) ||
      notEqualItemList(this.state.data.unassigned, this.lastData.unassigned) ||
      this.state.data.defaultLabels !== this.lastData.defaultLabels
    ) {
      const run = !this.lastRunData || shouldRun(this.state.data.assigned, this.lastRunData.assigned);
      this.props.save(this.state.data, true);
      this.lastData = this.state.data;
      if (run) {
        this.lastRunData = { ...this.state.data };
      }
    }
  };

  setAssignedList = (key, list, old) => {
    const newList = [...this.state.data.assigned];
    let idx = 0;
    let items;

    if (old.length === list.length) {
      items = newList[key].items.map(i => list.indexOf(i) === -1 ? i : list[idx++]);
    } else if (old.length > list.length) {
      items = newList[key].items.filter(i => !old.includes(i) || list.includes(i));
    } else {
      items = [...newList[key].items];
      let idx = 0;
      list.forEach(l => {
        let newIdx = old.indexOf(l);
        if (newIdx > -1) {
          idx = newIdx + 1;
        } else {
          items.splice(idx, 0, l);
          idx += 1;
        }
      });
    }
    newList[key] = {
      ...newList[key],
      items: items.map(i => ({
        ...i,
        group: newList[key].groupId,
        groupName: newList[key].group_name,
        groupName0: newList[key].group_name,
        organization_unit: newList[key].group_name,
      })),
    };

    this.setState(
      {
        data: {
          ...this.state.data,
          assigned: newList,
        },
      },
      () => {
        if (list.length < old.length) {
          this.save();
        }
      },
    );
  };

  setChoosen = (item) => {
    this.setState({
      choosen:
        this.state.choosen && item.id === this.state.choosen.id ? null : item,
    });
    return true;
  };

  setDialogItem = (item) => {
    this.setState({
      dialogItem: item,
    });
  };

  deselectItem = () => {
    if (this.state.choosen) {
      this.setState({
        choosen: null,
      });
    }
  };

  removeGroup = (key) => {
    let newGroups = [...this.state.data.assigned];
    let [removed] = newGroups.splice(key, 1);
    this.setState(
      {
        data: {
          ...this.state.data,
          assigned: newGroups,
          unassigned: [...this.state.data.unassigned, ...removed.items.map(i => ({
            ...i,
            group: 'gr',
          }))],
        },
      },
      () => this.save(),
    );
  };

  changeTitle = (key, value, confirm) => {
    let ret = null;
    if (
      this.state.data.assigned.find(
        (group, id) => key !== id && group.group_name === value,
      )
    ) {
      ret = 'Duplicate group name exist';
    }
    if (!value) {
      ret = 'Group name can\'t be empty';
    }
    const newData = [...this.state.data.assigned];
    newData[key].group_name = value;
    this.setState(
      {
        data: {
          ...this.state.data,
          assigned: newData,
        },
      },
      () => {
        if (confirm && !ret) this.save();
      },
    );
    return ret;
  };

  selectGroup = (key) => {
    const newState = this.state.data.assigned.map((group) => ({
      ...group,
      select: false,
    }));
    newState[key].select = true;
    this.setState({
      data: {
        ...this.state.data,
        assigned: newState,
      },
    });
  };

  deselectGroup = () => {
    const newState = this.state.data.assigned.map((group) => ({
      ...group,
      select: false,
    }));
    this.setState({
      data: {
        ...this.state.data,
        assigned: newState,
      },
    });
  };

  getGroupFTE = (groupKey, nodes) => {
    const sum = nodes
      .filter(node => node.group === groupKey)
      .reduce((acc, node) => acc + parseFloat(node.fte || 1), 0);
  
    return parseFloat(sum.toFixed(2));
  };
  

  renderGroup = (key, group) => {
    const { choosen } = this.state;
    const { termFilter } = this.props;
    const groupFTE = this.getGroupFTE(group.groupId, this.props.data.nodes);
    return (
      <div
        className={classnames('group-content', { selected: group.select })}
        data-id={key}
      >
        <GroupHeader
          group={group}
          onRemove={() => this.removeGroup(key)}
          onChangeTitle={(value, confirm) =>
            this.changeTitle(key, value, confirm)
          }
          onClick={() => this.selectGroup(key)}
          fte={groupFTE}
        />
        <div
          className={classnames('cards-wrapper', { active: choosen === key })}
          style={{ width: `${this.state.width * 3 + 55}px` }}
        >
          <SortableItemsWrapper
            items={getTermFilteredNode(group.items, termFilter)}
            setList={(list, old) => this.setAssignedList(key, list, old)}
            choosen={choosen}
            setChoosen={this.setChoosen}
            setDialogItem={this.setDialogItem}
            linkmap={this.linkmap}
            deselect={this.deselectItem}
            labels={this.labels}
            width={this.state.width}
            height={this.state.height}
            demo={this.props.demo}
            onCopy={this.onCopy}
            onDelete={this.onDelete}
          />
        </div>
      </div>
    );
  };

  setGroupOrder = (groups) => {
    let idx = 0;
    const assigned = this.state.data.assigned.map(g => groups.indexOf(g) === -1 ? g : groups[idx++]);
    this.setState(
      {
        data: {
          ...this.state.data,
          assigned: assigned.map((g, idx) => ({
            ...g,
            items: g.items.map((i) => ({
              ...i,
              group: g.groupId,
            })),
          })),
        },
      },
      () => this.save(),
    );
  };

  calcWidth = (word) => {
    let f = '16px Open Sans';
    let element = document.createElement('canvas');
    let context = element.getContext('2d');
    context.font = f;
    let width = context.measureText(word).width;
    return width;
  };

  componentDidMount() {
    this.groupSubscriber = installClickOutside(
      'group-content',
      this.deselectGroup,
    );
    this.cardSubscriber = installClickOutside('card-item', this.deselectItem);
  }

  updateCardSize = () => {
    const width =
      Math.max(...this.props.labels.map((label) => this.maxLabelWidth[label])) +
      9;
    this.setState(
      {
        width,
      },
      this.updateHeight,
    );
  };

  updateHeight = () => {
    const heights = [...document.getElementsByClassName('card-name')].map(
      (element) => element.offsetHeight,
    );
    const height = Math.max(...heights) + 4;
    this.setState({
      height,
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.groupSubscriber);
    document.removeEventListener('mousedown', this.cardSubscriber);
  }

  setunassigned = (list, old) => {
    let idx = 0;
    let unassigned;
    if (old.length === list.length) {
      unassigned = this.state.data.unassigned.map(i => list.indexOf(i) === -1 ? i : list[idx++]);
    } else if (old.length > list.length) {
      unassigned = this.state.data.unassigned.filter(i => !old.includes(i) || list.includes(i));
    } else {
      unassigned = [...this.state.data.unassigned];
      let idx = 0;
      list.forEach(l => {
        let newIdx = old.indexOf(l);
        if (newIdx > -1) {
          idx = newIdx + 1;
        } else {
          unassigned.splice(idx, 0, l);
          idx += 1;
        }
      });
    }

    this.setState(
      {
        data: {
          ...this.state.data,
          unassigned: unassigned.map(i => ({
            ...i,
            group: 'gr',
            organization_unit: '',
          })),
        },
      },
      () => {
        if (list.length < old.length) {
          this.save();
        }
      },
    );
  };

  onChangeItem = (newItem) => {
    this.setState({
      data: {
        ...this.state.data,
        assigned: this.state.data.assigned.map(a => ({
          ...a,
          items: a.items.map(i => i.id === newItem.id ? newItem : i),
        })),
        unassigned: this.state.data.unassigned.map(i => i.id === newItem.id ? newItem : i),
      },
    }, () => this.save(true));
  };

  addResource = () => {
    this.setState({
      data: {
        ...this.state.data,
        unassigned: [
          ...this.state.data.unassigned,
          {
            id: generateuniqueIshId(),
            employee_number: '',
            title: '',
            division: '',
            group: 'gr',
            groupName: '',
            color: getColorByName('No group'),
            name: '',
            first_name: '',
            last_name: '',
            email: '',
            role: '',
            organization_unit: '',
            fte: 1,
            reporting_to: '', 
          },
        ],
      },
    }, () => this.save(true));
  };

  deleteEmployee = (item) => {
    this.setState({
      data: {
        ...this.state.data,
        assigned: this.state.data.assigned.map(al => ({
          ...al,
          items: al.items.filter(u => u.id !== item.id),
        })),
        unassigned: this.state.data.unassigned.filter(u => u.id !== item.id),
      },
    }, () => this.save(true));
  };

  getAllItems = () => {
    return [...this.state.data.assigned.map(u => u.items), this.state.data.unassigned].flat();
  };

  setLabels = (defaultLabels) => {
    this.setState({
      data: {
        ...this.state.data,
        defaultLabels,
      },
    }, () => {
      this.save(false);
    });
  };

  onCopy = (item) => {
    this.setState({
      data: {
        ...this.state.data,
        assigned: this.state.data.assigned.map(al => ({
          ...al,
          items: addDuplicate(al.items, item),
        })),
        unassigned: addDuplicate(this.state.data.unassigned, item),
      },
    }, () => this.save(true));
  };

  onDelete = (item) => {
    this.deleteEmployee(item);
  };

  toggleImportResource = () => {
    this.setState({
      importResourceModal: !this.state.importResourceModal,
    });
  };

  toggleImportTimeUtil = () => {
    this.setState({
      importTimeUtilModal: !this.state.importTimeUtilModal,
    });
  };

  toggleGenerateTimeUtilization = () => {
    this.setState({
      generateTimeUtilizationModal: !this.state.generateTimeUtilizationModal,
    });
  };

  handleImportResourceSubmit = (data) => {
    this.toggleImportResource();
    const headers = data.headers;
    const hasHeader = data.hasHeader;
    const rows = data.rows;
    const defaultLabels = {
      groupName: (hasHeader ? data.group !== undefined && headers[data.group] : 'Group') || this.state.data.defaultLabels.groupName,
      name: (hasHeader ? data.name !== undefined && headers[data.name] : 'Name') || this.state.data.defaultLabels.name,
    };

    let groups = [];
    if (data.group !== undefined) {
      groups = [...new Set(rows.map(d => d[data.group]).filter(c => !!c))];
    }

    const nodes = rows.map(d => {
      const groupName = data.group !== undefined ? d[data.group] : undefined;
      let group = '';
      if (data.group !== undefined) {
        group = groups.indexOf(groupName);
      }
      const name = data.name !== undefined && d[data.name];
      return {
        id: `${Math.floor(1000000000 + Math.random() * 9000000000)}`,
        groupName,
        group: `gr${group}`,
        name,
        color: getColorByName(group || -1),
      };
    });

    headers.forEach((title, id) => {
      if (id !== data.group && id !== data.name) {
        const key = toSlug(title);
        defaultLabels[key] = title;
        rows.forEach((d, nid) => {
          nodes[nid][key] = d[id];
        });
      }
    });

    this.setState({
      data: {
        ...this.state.data,
        assigned: groups.map(d => ({
          items: nodes.filter(n => n.groupName === d),
          groupId: `gr${groups.indexOf(d)}`,
          group_name: d,
        })),
        unassigned: nodes.filter(n => n.groupName === undefined),
        defaultLabels,
      },
    }, () => this.save(true));
  };

  handleImportTimeUtilSubmit = (data) => {
    this.toggleImportTimeUtil();
    const { rows, headers, resource } = data;
    const nodes = this.getAllItems();
    const activities = [...(this.props.data.activities || [])];
    const activityIds = headers.map((h, idx) => {
      if (idx === resource) {
        return null;
      }
      let activity;
      if (Array.isArray(h) && h.length === 2) {
        activity = activities.find(a => a.activity === h[1] && a.function === h[0]);
        if (!activity) {
          activity = {
            id: generateuniqueIshId(),
            function_id: generateuniqueIshId(),
            activity: h[1],
            function: h[0],
          };
          activities.push(activity);
        }
      } else if (typeof h === 'string') {
        activity = activities.find(a => a.name === h);
        if (!activity) {
          activity = {
            id: generateuniqueIshId(),
            function_id: generateuniqueIshId(),
            activity: h,
            function: 'Default',
          };
          activities.push(activity);
        }
      } else {
        console.error('invalid data for header', h);
      }
      if (activity) {
        return activity.id;
      }
      return null;
    });
    rows.forEach(row => {
      let node = nodes.find(n => n.name === row[resource]);
      if (node) {
        node.time_utilization = {};
        headers.forEach((h, idx) => {
          if (activityIds[idx] === null) {
            return;
          }
          let value = row[idx];
          if (!row[idx].includes('%')) {
            value = `${(+row[idx]) * 100}%`;
          }

          let numberValue = +value.replace('%', '');
          if (numberValue > 0) {
            node.time_utilization[activityIds[idx]] = value.replace(/\s/g, '');
          }
        });
      }
    });

    this.setState({
      data: {
        ...this.state.data,
        activities,
      },
    }, () => this.save(true));
  };

  renderImportResourceModal = () => {
    const { importResourceModal } = this.state;

    return (
      <Modal isOpen={importResourceModal} toggle={this.toggleImportResource}>
        <ModalHeader>Import resources</ModalHeader>
        <CSVWizard
          toggle={this.toggleImportResource}
          handleSubmit={this.handleImportResourceSubmit}
          specifyColumns={[
            { key: 'group', label: 'Column with group assignment', placeholder: 'Group', required: true },
            { key: 'name', label: 'Column with name assignment', placeholder: 'Name', required: true },
          ]}
        />
      </Modal>
    );
  };

  renderImportTimeUtilModal = () => {
    const { importTimeUtilModal } = this.state;

    return (
      <Modal isOpen={importTimeUtilModal} toggle={this.toggleImportTimeUtil}>
        <ModalHeader>Import time spent per activity per employee</ModalHeader>
        <CSVWizard
          toggle={this.toggleImportTimeUtil}
          handleSubmit={this.handleImportTimeUtilSubmit}
          specifyColumns={[{
            key: 'resource',
            label: 'Column with resource identifiers',
            placeholder: 'Resource',
            required: true,
          }]}
          forceHasHeader={true}
          resources={this.props.data.nodes}
          activities={this.props.data.activities}
        />
      </Modal>
    );
  };

  renderGenerateTimeUtilizationModal = () => {
    const { generateTimeUtilizationModal, generatingTimeUtilizationLoading } = this.state;

    return (
      <Modal isOpen={generateTimeUtilizationModal } toggle={this.toggleGenerateTimeUtilization}>
        <ModalHeader>Do you want to generate synthetic time utilization data?</ModalHeader>
        {generatingTimeUtilizationLoading && (
        <div className="loading-spin">
          <Loading text="Coming up with a distribution of work for your employees..." />
        </div>
        )}
        
        <ModalFooter>
          <button onClick={this.generateTimeUtilization} className="button" disabled={generatingTimeUtilizationLoading}>
            overwrite existing data
            <span className="material-symbols-outlined">neurology</span>
          </button>
         {/* <button onClick={this.generateTimeUtilization} className="button">
            Extend with more data
            <span className="material-symbols-outlined">neurology</span>
          </button>*/}
        </ModalFooter>

      </Modal>
    );
  };

  generateTimeUtilization = async () => {
    const { nodes, activities } = this.props.data;
    this.setState({generatingTimeUtilizationLoading: true});
    
    const apiPayload = {
        'prompt_template': 'generate_time_utilization',
        'design_id': this.props.data.id,
        'employees': nodes,
        'activities': activities,
    };
      
    try {
        const apiResponse = await postSearchApi(apiPayload);
        this.props.getDesignOrg(this.props.data.id);
        this.setState({generatingTimeUtilizationLoading: false, generateTimeUtilizationModal: false});
    } catch (error) {
        console.error('Errr message: ', error);
        this.setState({generatingTimeUtilizationLoading: false,
          generateTimeUtilizationModal: false});
    }
  }


  render() {
    let {
      data: { assigned, unassigned, defaultLabels },
      choosen,
      dialogItem,
    } = this.state;
    this.labels = this.props.labels.filter(l => defaultLabels[l]);

    return (
      <div className="mt-2">
        <div className="d-flex">
          <SortableGroupsWrapper
            groups={assigned}
            renderGroup={this.renderGroup}
            setList={this.setGroupOrder}
            className="groups-wrapper"
            deselect={this.deselectGroup}
            demo={this.props.demo}
          />
          <div className="design-controls">
            {/*<div style={{ margin: '10px' }}>
              <button onClick={this.toggleImportResource} className="button">
                Import resources&nbsp;
                <SVG
                  icon="import.svg#file-upload"
                  iconWidth={18}
                  iconHeight={20}
                  iconViewWidth={100}
                  iconViewHeight={123}
                  iconClassName="button__icon button__icon-blue"
                />
              </button>
              {this.renderImportResourceModal()}
            </div>*/}
            <div style={{ margin: '10px' }}>
              <button onClick={this.toggleImportTimeUtil} className="button">
                Import Time utilization&nbsp;
                <SVG
                  icon="import.svg#file-upload"
                  iconWidth={18}
                  iconHeight={20}
                  iconViewWidth={100}
                  iconViewHeight={123}
                  iconClassName="button__icon button__icon-blue"
                />
              </button>
              {this.renderImportTimeUtilModal()}
            </div>
            <div style={{ margin: '10px' }}>
              <button onClick={this.toggleGenerateTimeUtilization} className="button">
                Generate synthetic Time utilization data&nbsp;
                <span className="material-symbols-outlined">neurology</span>
              </button>
              {this.renderGenerateTimeUtilizationModal()}
            </div>
            <div className="group">
              <Button
                text="Add Resource"
                className="button"
                icon="plus.svg#plus"
                onClick={this.addResource}
                style={{ margin: '10px' }}
              />
              <Button
                text="Add Group"
                className="button"
                icon="plus.svg#plus"
                onClick={this.addGroup}
                style={{ margin: '10px' }}
              />
            </div>
            <div className="unassinged-items">
              <SortableItemsWrapper
                items={unassigned}
                setList={this.setunassigned}
                setChoosen={this.setChoosen}
                setDialogItem={this.setDialogItem}
                choosen={choosen}
                linkmap={this.linkmap}
                labels={this.labels}
                width={this.state.width}
                height={this.state.height}
                demo={this.props.demo}
                onCopy={this.onCopy}
                onDelete={this.onDelete}
              />
            </div>
          </div>
        </div>
        {dialogItem &&
          <ItemEditModal
            item={dialogItem}
            setItem={this.onChangeItem}
            activityOptions={this.props.data.activityOptions}
            linkmap={this.linkmap}
            activityMap={this.props.data.activityMap}
            toggle={() => this.setDialogItem(null)}
            deleteItem={this.deleteEmployee}
            defaultLabels={defaultLabels || {}}
            setLabels={this.setLabels}
          />
        }
      </div>
    );
  }
}

export default OrganizationTab;
